import React from 'react'

const Timer = ({ time }) => {
  return (
    <div>
      {time}
    </div>
  )
}

export default Timer